import { Asterisk, Image, Money } from "phosphor-react";
import { customPuzzle, getGiftPrice } from "./utils";
import Price from "./Price";

function FormGiftPuzzleImageUploader(props) {
  return (
    <>
      <label
        htmlFor="form_gift_custom_puzzle_picture"
        className="vertical-alignment"
      >
        <Image size={16} weight="bold" className="neutral-color" /> Качете
        снимка за пъзел{" "}
        <Asterisk size={16} weight="bold" className="form-label-asterisk" />
      </label>
      {props.form_gift_custom_puzzle_picture_error && (
        <span className="form-error-message">
          {props.form_gift_custom_puzzle_picture_error.message}
        </span>
      )}
      <input
        type="file"
        disabled={props.isSubmitting}
        {...props.register("form_gift_custom_puzzle_picture", {
          required: "Моля, качете снимка за пъзел.",
        })}
        accept={"image/*"}
        className="form-input-file"
      />
      <div className="vertical-alignment" style={{ marginTop: "16px" }}>
        <Money size={16} weight="bold" className="neutral-color" />
        Цена
      </div>
      <Price price={getGiftPrice(customPuzzle)} promoCode={props.promoCode} />
    </>
  );
}

export default FormGiftPuzzleImageUploader;
