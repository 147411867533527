import React, { useState, useRef } from "react";
import { Info, Gift, Truck, CaretRight, CaretLeft } from "phosphor-react";
import OrderForm from "./OrderForm";
import Footer from "./Footer";
import { premadePuzzleTypes } from "./utils";

function Home() {
  const infoRef = useRef(null);
  const productsRef = useRef(null);
  const ordersRef = useRef(null);

  const navigateToRef = (ref, block = "center") => {
    ref.current.scrollIntoView({
      behavior: "smooth",
      block: block,
    });
  };

  const navigateToOrdersRef = () => {
    navigateToRef(ordersRef, "start");
  };

  const gallery = premadePuzzleTypes.map(
    (puzzleType) => `gallery-${puzzleType}.png`
  );

  const [currentGalleryIndex, setCurrentGalleryIndex] = useState(0);
  const goToPrevGalleryIndex = () => {
    setCurrentGalleryIndex((curr) => Math.max(curr - 1, 0));
  };
  const goToNextGalleryIndex = () => {
    setCurrentGalleryIndex((curr) => Math.min(curr + 1, gallery.length - 1));
  };

  return (
    <>
      <nav className="fixed-nav">
        <div className="nav-block">
          <img src="/logo-with-heading.png" className="nav-logo" />
        </div>

        <div className="nav-block">
          <button className="nav-button" onClick={() => navigateToOrdersRef()}>
            Поръчай сега
          </button>
        </div>
      </nav>

      <section>
        <div className="section-block section-limited-block">
          <h1>Персонализирани QR подаръци</h1>

          <p>
            Потопете се в магията на творчеството с нашите подаръчни QR кодове -
            пресъздайте вашите любими снимки, видеа, песни и други дигитални
            спомени или подарете ваучер по уникален и забавен начин.
          </p>

          <div className="heading-section-nav">
            <span
              className="heading-section-nav-link"
              onClick={() => navigateToRef(infoRef)}
            >
              <Info size={20} />
              <span className="heading-section-nav-link-text"> Информация</span>
            </span>
            <span
              className="heading-section-nav-link"
              onClick={() => navigateToRef(productsRef)}
            >
              <Gift size={20} />
              <span className="heading-section-nav-link-text"> Продукти</span>
            </span>
            <span
              className="heading-section-nav-link"
              onClick={() => navigateToOrdersRef()}
            >
              <Truck size={20} />
              <span className="heading-section-nav-link-text"> Поръчки</span>
            </span>
          </div>
        </div>

        <div className="large-screen-content">
          <img src="/heading-image.png" className="heading-section-image" />
        </div>
      </section>

      <section ref={infoRef}>
        <div className="large-screen-content">
          <img src="/instructions-image.png" className="instructions-image" />
        </div>
        <div className="section-block section-limited-block">
          <h2>Как работят QR подаръците</h2>

          <div className="section-block-badge-paragraph">
            <span className="badge">1</span>
            <p>
              Качете вашия дигитален спомен или предварително закупен ваучер и
              изберете типа физически подарък
            </p>
          </div>
          <div className="section-block-badge-paragraph">
            <span className="badge">2</span>
            <p>Поръчайте вашия QR подарък</p>
          </div>
          <div className="section-block-badge-paragraph">
            <span className="badge">3</span>
            <p>Подарете QR подаръка на вашите близки</p>
          </div>
          <div className="section-block-badge-paragraph">
            <span className="badge">4</span>
            <p>
              Вашите близки сканират QR кода от подаръка и се наслаждават на
              изненадата
            </p>
          </div>

          <span
            className="badge pointer section-next-link"
            onClick={() => navigateToRef(productsRef)}
          >
            Към продуктите
          </span>
        </div>
      </section>

      <section ref={productsRef}>
        <div className="section-block section-limited-block">
          <h2>Нашите продукти</h2>

          <p>
            Всички наши продукти са съчетани с QR код, към който вие прикачвате
            вашия дигитален спомен или ваучер.
          </p>
          <p>
            <b>ВАЖНО:</b> съдържанието на всеки QR код е активно за период от 2
            години от датата на получаване. След изтичане на този период, ще се
            свържем с вас, за да разберем дали искате да удължите периода срещу
            допълнително заплащане или желаете съдържанието да бъде изтрито.
          </p>
        </div>

        <div className="carousel">
          <span
            className="carousel-prev"
            style={currentGalleryIndex == 0 ? { display: "none" } : {}}
            onClick={goToPrevGalleryIndex}
          >
            <CaretLeft size={20} weight="bold" />
          </span>
          <div className="gallery-block">
            <img
              src={gallery[currentGalleryIndex]}
              className="carousel-image"
            />
          </div>
          <span
            className="carousel-next"
            style={
              currentGalleryIndex == gallery.length - 1
                ? { display: "none" }
                : {}
            }
            onClick={goToNextGalleryIndex}
          >
            <CaretRight size={20} weight="bold" />
          </span>
        </div>
        <span
          className="badge pointer section-next-link small-screen-content"
          onClick={() => navigateToOrdersRef()}
        >
          Поръчай сега
        </span>
      </section>

      <section ref={ordersRef}>
        <OrderForm
          navigateToProductsRef={() => {
            navigateToRef(productsRef);
          }}
          navigateToOrdersRef={navigateToOrdersRef}
        />
      </section>

      <Footer />
    </>
  );
}

export default Home;
