import React, { useState } from "react";
import {
  Asterisk,
  User,
  Envelope,
  Phone,
  MusicNotes,
  Microphone,
  VideoCamera,
  Image,
  Tag,
  ListBullets,
  CaretRight,
  Check,
  Gift,
  Truck,
  Package,
  Coins,
  FilePdf,
} from "phosphor-react";
import { SealPercent } from "@phosphor-icons/react";
import { RotatingLines } from "react-loader-spinner";
import { useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import FormGiftPuzzleVariationPicker from "./FormGiftPuzzleVariationPicker";
import FormGiftPuzzleImageUploader from "./FormGiftPuzzleImageUploader";
import {
  customPuzzle,
  getGiftPrice,
  limitedSeriesPuzzleTypes,
  premadePuzzleTypes,
} from "./utils";
import Price from "./Price";

const formGiftCategoryPremadePuzzleDesigns = "premade-puzzle-designs";
const formGiftCategoryCustomPuzzleDesign = "custom-puzzle-design";
const formGiftCategoryLimitedSeriesPuzzleDesigns =
  "limited-series-puzzle-designs";
const formGiftCategoryTextMapping = {
  [formGiftCategoryPremadePuzzleDesigns]: "Пъзел (наш дизайн)",
  [formGiftCategoryCustomPuzzleDesign]: "Пъзел (по ваша снимка)",
  // [formGiftCategoryLimitedSeriesPuzzleDesigns]: "Пъзел (лимитирана серия)",
};

function OrderForm(props) {
  const {
    register,
    handleSubmit,
    clearErrors,
    formState: { errors, isSubmitting },
    watch,
    setValue,
    setError,
    reset,
  } = useForm({
    criteriaMode: "all",
    defaultValues: {
      delivery_method: "econt",
    },
  });
  const formGiftContentType = watch("form_gift_content_type", "song_url");
  const formGiftType = watch("form_gift_type");
  const formFile = watch("form_file");
  const formPromoCode = watch("form_promo_code");
  const formPromoCodeOn = watch("form_promo_code_confirmation", false);
  const deliveryMethod = watch("delivery_method");
  const [formSubmitError, setFormSubmitError] = useState(null);
  const [formSuccessMessage, setFormSuccessMessage] = useState(null);
  const [showFileConvertionConfirmation, setShowFileConvertionConfirmation] =
    useState(false);

  const currentDate = new Date();
  const freeShippingEndDate = new Date("2024-07-31");
  const freeShippingOn = currentDate < freeShippingEndDate;

  React.useEffect(() => {
    if (formFile && formFile[0]) {
      setShowFileConvertionConfirmation(formFile[0].name.endsWith(".mov"));
    }
  }, [formFile]);

  React.useEffect(() => {
    setValue("form_file", null);
  }, [formGiftContentType]);

  const handlePuzzleVariationChange = (puzzleType) => {
    clearErrors("form_gift_type");
    setValue("form_gift_type", puzzleType);
  };

  const [promoCode, setPromoCode] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const urlPromoCode = searchParams?.get("promo_code");

  const [selectedFormGiftCategory, setSelectedFormGiftCategory] = useState(
    formGiftCategoryPremadePuzzleDesigns
  );
  const handleFormGiftCategoryChange = (category) => {
    clearErrors("form_gift_category");
    clearErrors("form_gift_type");
    setSelectedFormGiftCategory(category);

    if (category === formGiftCategoryCustomPuzzleDesign) {
      setValue("form_gift_type", customPuzzle);
    } else {
      setValue("form_gift_type", null);
    }
  };

  const handleFormPromoCodeConfirmationChange = (checked) => {
    if (!checked) {
      setValue("form_promo_code", null);
      setPromoCode(null);
    }

    setValue("form_promo_code_confirmation", checked);
  };

  React.useEffect(() => {
    if (urlPromoCode) {
      setValue("form_promo_code_confirmation", true);
      setValue("form_promo_code", urlPromoCode);
      validatePromoCode(urlPromoCode);
      props.navigateToOrdersRef();
    }
  }, [urlPromoCode]);

  const validatePromoCode = (promoCodeID) => {
    if (promoCode !== null || promoCodeID === "" || promoCodeID === null) {
      clearErrors("form_promo_code");
      return;
    }

    return fetch("/api/orders/promo-codes/" + promoCodeID, {
      method: "GET",
    })
      .then((resp) => {
        if (!resp.ok) {
          if (resp.status === 404) {
            throw new Error("Невалиден промо код.");
          }

          throw new Error(
            "Неочаквана грешка при валидиране на промо код. Моля, опитайте отново или се свържете с нас за съдействие."
          );
        }

        return resp.json();
      })
      .then((data) => {
        setPromoCode(data);
        clearErrors("form_promo_code");
      })
      .catch((err) => {
        setError("form_promo_code", {
          type: "custom",
          message: err?.message || err || "Неочаквана грешка.",
        });
      });
  };

  const createOrder = (data) => {
    const formData = new FormData();

    const reqBody = {
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      phone_number: data.mobile_number,
      delivery_method: data.delivery_method,
      delivery_address: data.delivery_address,
      gift_type: data.form_gift_type,
      content_type: data.form_gift_content_type,
    };

    if (data.form_gift_type === customPuzzle) {
      formData.append(
        "custom_puzzle_picture",
        data.form_gift_custom_puzzle_picture[0],
        data.form_gift_custom_puzzle_picture[0].name
      );
    }

    if (data.form_gift_content_type === "song_url") {
      reqBody["song_url"] = data.form_song_link;
    } else {
      formData.append(
        "gift_content",
        data.form_file[0],
        data.form_file[0].name
      );
    }

    if (data.form_promo_code && promoCode !== null) {
      reqBody["promo_code_id"] = data.form_promo_code;
    }

    formData.append("order_data", JSON.stringify(reqBody));

    return fetch("/api/orders", {
      method: "POST",
      body: formData,
    }).then((resp) => {
      if (!resp.ok) {
        if (resp.status === 404) {
          return resp.json().then((data) => {
            if (data.error === "promo code not found or is no longer valid") {
              setPromoCode(null);
              throw new Error("Невалиден промо код.");
            }
          });
        }

        throw new Error(
          "Неочаквана грешка при изпращане на заявката. Моля, опитайте отново или се свържете с нас за съдействие."
        );
      }

      setPromoCode(null);
      setFormSuccessMessage(
        "Вашата заявка беше изпратена успешно. Ще се свържем с вас за потвърждение."
      );

      const shouldSetCategory =
        data.form_gift_category === formGiftCategoryCustomPuzzleDesign;
      reset();
      setSearchParams({});

      if (shouldSetCategory) {
        setValue("form_gift_category", formGiftCategoryCustomPuzzleDesign);
        setValue("form_gift_type", customPuzzle);
      }
    });
  };

  const onSubmit = (data) => {
    if (promoCode === null && data.form_promo_code) {
      setFormSubmitError(
        "Моля, валидирайте или изтрийте промо кода преди да направите поръчка."
      );

      return;
    }

    return createOrder(data).catch((err) => {
      setFormSubmitError(err?.message || err || "Неочаквана грешка.");
    });
  };

  return (
    <div className="section-block form-section-block">
      <h3>Направи заявка</h3>

      <p>След успешна заявка ще се свържем с вас за потвърждение</p>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-row">
          <div className="form-row-col">
            <label htmlFor="form_gift_category" className="vertical-alignment">
              <Gift size={16} weight="bold" className="neutral-color" />{" "}
              Изберете подарък
            </label>
            <select
              id="form_gift_category"
              {...register("form_gift_category", {
                required: "Моля, изберете категория за подаръци.",
              })}
              value={selectedFormGiftCategory}
              onChange={(e) => handleFormGiftCategoryChange(e.target.value)}
            >
              {Object.keys(formGiftCategoryTextMapping).map((category) => (
                <option key={category} value={category}>
                  {formGiftCategoryTextMapping[category]}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="form-row">
          <div className="form-row-col">
            {selectedFormGiftCategory ===
              formGiftCategoryPremadePuzzleDesigns && (
              <FormGiftPuzzleVariationPicker
                puzzleTypes={premadePuzzleTypes}
                form_gift_type_error={errors?.form_gift_type}
                register={register}
                formGiftType={formGiftType}
                handlePuzzleVariationChange={handlePuzzleVariationChange}
                promoCode={promoCode}
              />
            )}
            {selectedFormGiftCategory ===
              formGiftCategoryCustomPuzzleDesign && (
              <FormGiftPuzzleImageUploader
                form_gift_custom_puzzle_picture_error={
                  errors?.form_gift_custom_puzzle_picture
                }
                register={register}
                promoCode={promoCode}
              />
            )}
          </div>
        </div>

        <div className="form-row">
          <div className="form-row-col">
            {freeShippingOn && (
              <p>
                <strong style={{ color: "#fdc43f" }}>БЕЗПЛАТНА ДОСТАВКА</strong>{" "}
                за поръчки направени до 31-ви Юли
              </p>
            )}
          </div>
        </div>

        <div className="form-row">
          <div className="form-row-col">
            <label htmlFor="first_name" className="vertical-alignment">
              <User size={16} weight="bold" className="neutral-color" /> Име{" "}
              <Asterisk
                size={16}
                weight="bold"
                className="form-label-asterisk"
              />
            </label>
            {errors && errors.first_name && (
              <span className="form-error-message">
                {errors.first_name.message}
              </span>
            )}
            <input
              type="text"
              id="first_name"
              maxLength={64}
              disabled={isSubmitting}
              {...register("first_name", {
                required: "Моля, въведете име.",
              })}
            />
          </div>
          <div className="form-row-col">
            <label htmlFor="last_name" className="vertical-alignment">
              <User size={16} weight="bold" className="neutral-color" /> Фамилия{" "}
              <Asterisk
                size={16}
                weight="bold"
                className="form-label-asterisk"
              />
            </label>
            {errors && errors.last_name && (
              <span className="form-error-message">
                {errors.last_name.message}
              </span>
            )}
            <input
              type="text"
              id="last_name"
              maxLength={64}
              disabled={isSubmitting}
              {...register("last_name", {
                required: "Моля, въведете фамилия.",
              })}
            />
          </div>
        </div>

        <div className="form-row">
          <div className="form-row-col">
            <label htmlFor="email" className="vertical-alignment">
              <Envelope size={16} weight="bold" className="neutral-color" />{" "}
              Имейл{" "}
              <Asterisk
                size={16}
                weight="bold"
                className="form-label-asterisk"
              />
            </label>
            {errors && errors.email && (
              <span className="form-error-message">{errors.email.message}</span>
            )}
            <input
              type="email"
              id="email"
              disabled={isSubmitting}
              {...register("email", {
                required: "Моля, въведете имейл.",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: "Моля, въведете валиден имейл адрес.",
                },
              })}
            />
          </div>
          <div className="form-row-col">
            <label htmlFor="mobile_number" className="vertical-alignment">
              <Phone size={16} weight="bold" className="neutral-color" />{" "}
              Мобилен номер{" "}
              <Asterisk
                size={16}
                weight="bold"
                className="form-label-asterisk"
              />
            </label>
            {errors && errors.mobile_number && (
              <span className="form-error-message">
                {errors.mobile_number.message}
              </span>
            )}
            <input
              type="tel"
              id="mobile_number"
              disabled={isSubmitting}
              {...register("mobile_number", {
                required: "Моля, въведете мобилния си номер.",
                pattern: {
                  value: /(08|\+3598)[7-9][0-9]{7}/,
                  message: "Моля, въведете валиден мобилен номер.",
                },
              })}
            />
          </div>
        </div>

        <div className="form-row">
          <div className="form-row-col">
            <label
              htmlFor="form_gift_content_type"
              className="vertical-alignment"
            >
              <ListBullets size={16} weight="bold" className="neutral-color" />{" "}
              Изберете тип съдържание
            </label>
            {errors && errors.form_gift_content_type && (
              <span className="form-error-message">
                {errors.form_gift_content_type.message}
              </span>
            )}
            <select
              id="form_gift_content_type"
              {...register("form_gift_content_type", {
                required: "Моля, изберете тип съдържание.",
              })}
            >
              <option value="video">Видео</option>
              <option value="voucher">Ваучер</option>
              <option value="song_url">Линк към песен</option>
              <option value="picture">Снимка</option>
              <option value="audio">Звукозапис</option>
            </select>
          </div>

          {["picture", "video", "audio", "voucher"].includes(
            formGiftContentType
          ) && (
            <div className="form-row-col">
              <label htmlFor="form_file" className="vertical-alignment">
                {getIconFromGiftContentType(formGiftContentType)} Качете{" "}
                {getLabelFromGiftContentType(formGiftContentType)}{" "}
                <Asterisk
                  size={16}
                  weight="bold"
                  className="form-label-asterisk"
                />
              </label>
              {errors && errors.form_file && (
                <span className="form-error-message">
                  {errors.form_file.message}
                </span>
              )}
              <input
                type="file"
                disabled={isSubmitting}
                {...register("form_file", { required: "Моля, качете файл." })}
                accept={getAcceptTypeFromGiftContentType(formGiftContentType)}
                className="form-input-file"
              />
            </div>
          )}

          {formGiftContentType === "song_url" && (
            <div className="form-row-col">
              <label htmlFor="form_song_link" className="vertical-alignment">
                <MusicNotes size={16} weight="bold" className="neutral-color" />{" "}
                Линк към песен{" "}
                <Asterisk
                  size={16}
                  weight="bold"
                  className="form-label-asterisk"
                />
              </label>
              {errors && errors.form_song_link && (
                <span className="form-error-message">
                  {errors.form_song_link.message}
                </span>
              )}
              <input
                type="url"
                id="form_song_link"
                disabled={isSubmitting}
                {...register("form_song_link", {
                  required: "Моля, въведете линк към песен.",
                  validate: (value) => {
                    const urlPattern =
                      /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be|open\.spotify\.com)\/.+$/;
                    return (
                      urlPattern.test(value) ||
                      "Моля въведете валиден YouTube или Spotify линк към песен"
                    );
                  },
                })}
              />
            </div>
          )}
        </div>

        <div className="form-row">
          <div className="form-row-col">
            <label htmlFor="delivery_method" className="vertical-alignment">
              <Package size={16} weight="bold" className="neutral-color" />
              Метод на доставка
            </label>
            {errors && errors.delivery_method && (
              <span className="form-error-message">
                {errors.delivery_method.message}
              </span>
            )}
            <div className="form-input-radio-button-div">
              <input
                type="radio"
                id="delivery_method"
                value="econt"
                checked={deliveryMethod === "econt"}
                {...register("delivery_method", {
                  required: "Моля, изберете метод на доставка.",
                })}
              />
              <label htmlFor="delivery_method">Еконт</label>
            </div>
            <div className="form-input-radio-button-div">
              <input
                type="radio"
                id="delivery_method"
                value="speedy"
                checked={deliveryMethod === "speedy"}
                {...register("delivery_method", {
                  required: "Моля, изберете метод на доставка.",
                })}
              />
              <label htmlFor="delivery_method">Спийди</label>
            </div>
            <div className="form-input-radio-button-div">
              <input
                type="radio"
                id="delivery_method"
                value="client_address"
                checked={deliveryMethod === "client_address"}
                {...register("delivery_method", {
                  required: "Моля, изберете метод на доставка.",
                })}
              />
              <label htmlFor="delivery_method">До адрес (чрез Еконт)</label>
            </div>
          </div>

          <div className="form-row-col">
            <label htmlFor="delivery_address" className="vertical-alignment">
              <Truck size={16} weight="bold" className="neutral-color" />
              {getDeliveryAddressLabel(deliveryMethod)}{" "}
              <Asterisk
                size={16}
                weight="bold"
                className="form-label-asterisk"
              />
            </label>
            {errors && errors.delivery_address && (
              <span className="form-error-message">
                {errors.delivery_address.message}
              </span>
            )}
            <textarea
              id="delivery_address"
              rows="6"
              cols="28"
              disabled={isSubmitting}
              style={{ resize: "none" }}
              {...register("delivery_address", {
                required: "Моля, въведете адрес за доставка.",
                maxLength: {
                  value: 1024,
                  message: "Адресът трябва да е до 1024 символа.",
                },
              })}
            />
          </div>
        </div>

        {showFileConvertionConfirmation && formGiftContentType == "video" && (
          <div className="form-row">
            <div className="form-row-col">
              {errors && errors.form_file_convert && (
                <span className="form-error-message">
                  {errors.form_file_convert.message}
                </span>
              )}
              <div className="form-input-confirmation">
                <label htmlFor="form_file_convert">
                  <Asterisk
                    size={16}
                    weight="bold"
                    className="form-label-asterisk"
                  />{" "}
                  Съгласявам се файлът да бъде конвертиран от .mov към .mp4
                </label>
                <input
                  type="checkbox"
                  {...register("form_file_convert", {
                    required: "Моля, потвърдете.",
                  })}
                />
              </div>
            </div>
          </div>
        )}

        <div className="form-row">
          <div className="form-row-col">
            {errors && errors.form_qr_code_expiration && (
              <span className="form-error-message">
                {errors.form_qr_code_expiration.message}
              </span>
            )}
            <div className="form-input-confirmation">
              <label htmlFor="form_qr_code_expiration">
                <Asterisk
                  size={16}
                  weight="bold"
                  className="form-label-asterisk"
                />{" "}
                Потвърждавам, че прочетох и се съгласявам с{" "}
                <span
                  className="keyword pointer"
                  onClick={props.navigateToProductsRef}
                >
                  информацията
                </span>{" "}
                относно продължителността на валидност на QR кода
              </label>
              <input
                type="checkbox"
                {...register("form_qr_code_expiration", {
                  required: "Моля, потвърдете.",
                })}
              />
            </div>
          </div>
        </div>

        <div className="form-row">
          <div className="form-row-col">
            {errors && errors.form_age_confirmation && (
              <span className="form-error-message">
                {errors.form_age_confirmation.message}
              </span>
            )}
            <div className="form-input-confirmation">
              <label htmlFor="form_age_confirmation">
                <Asterisk
                  size={16}
                  weight="bold"
                  className="form-label-asterisk"
                />{" "}
                Потвърждавам, че имам навършени 18 години
              </label>
              <input
                type="checkbox"
                {...register("form_age_confirmation", {
                  required: "Моля, потвърдете.",
                })}
              />
            </div>
          </div>
        </div>

        <div className="form-row">
          <div className="form-row-col">
            <div className="form-input-confirmation">
              <label
                htmlFor="form_promo_code_confirmation"
                className="vertical-alignment"
              >
                <SealPercent
                  size={16}
                  weight="bold"
                  className="neutral-color"
                />{" "}
                Искам да приложа промо код
              </label>
              <input
                type="checkbox"
                checked={formPromoCodeOn}
                {...register("form_promo_code_confirmation")}
                onChange={(e) =>
                  handleFormPromoCodeConfirmationChange(e.target.checked)
                }
              />
            </div>
          </div>
        </div>

        {formPromoCodeOn && (
          <div className="form-row">
            <div className="form-row-col">
              <label htmlFor="form_promo_code" className="vertical-alignment">
                <Tag size={16} weight="bold" className="neutral-color" /> Промо
                код
              </label>
              {errors && errors.form_promo_code && (
                <span className="form-error-message">
                  {errors.form_promo_code.message}
                </span>
              )}
              <div className="vertical-alignment" style={{ gap: "0px" }}>
                <input
                  type="text"
                  maxLength={16}
                  id="form_promo_code"
                  disabled={promoCode !== null || isSubmitting}
                  {...register("form_promo_code", {
                    maxLength: {
                      value: 16,
                      message: "Промо кодът трябва да е до 16 символа.",
                    },
                    onChange: (e) => {
                      const upperCaseValue = e.target.value.toUpperCase();
                      setValue("form_promo_code", upperCaseValue, {
                        shouldValidate: true,
                      });
                    },
                  })}
                  className="form-large-input"
                />
                <button
                  type="button"
                  className="form-input-button"
                  onClick={() => validatePromoCode(formPromoCode)}
                  disabled={promoCode !== null || isSubmitting}
                >
                  {promoCode === null ? (
                    <CaretRight size={16} weight="bold" />
                  ) : (
                    <Check size={16} weight="bold" />
                  )}
                </button>
              </div>
            </div>
          </div>
        )}

        {formGiftType && (
          <>
            <br />
            <div className="form-row">
              <div className="form-col">
                <div className="form-price-row">
                  <div className="vertical-alignment">
                    <Gift size={16} weight="bold" className="neutral-color" />{" "}
                    Подарък
                  </div>
                  <div className="vertical-alignment">
                    <Price
                      price={getGiftPrice(formGiftType)}
                      promoCode={promoCode}
                    />
                  </div>
                </div>

                <div className="form-price-row">
                  <div className="vertical-alignment">
                    <Truck size={16} weight="bold" className="neutral-color" />{" "}
                    Доставка (ориентир)
                  </div>
                  <div className="vertical-alignment">
                    <Price price={getDeliveryPrice(deliveryMethod)} />
                  </div>
                </div>

                <div className="form-price-row">
                  <div className="vertical-alignment">
                    <Coins size={16} weight="bold" className="neutral-color" />{" "}
                    Общo
                  </div>
                  <div className="vertical-alignment">
                    <Price
                      price={getGiftPrice(formGiftType)}
                      nonDiscountablePrice={getDeliveryPrice(deliveryMethod)}
                      promoCode={promoCode}
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        <div className="form-row width-100">
          <div className="form-row-col width-100">
            {formSubmitError && (
              <>
                <span className="form-error-message">{formSubmitError}</span>
                <br />
              </>
            )}

            {formSuccessMessage && (
              <>
                <span className="form-success-message">
                  {formSuccessMessage}
                </span>
                <br />
              </>
            )}

            {isSubmitting ? (
              <div style={{ alignSelf: "center" }}>
                <RotatingLines
                  visible={true}
                  height="64"
                  width="64"
                  strokeColor="#fff75e"
                  strokeWidth="5"
                  animationDuration="1"
                />
              </div>
            ) : (
              <button
                type="submit"
                disabled={isSubmitting}
                onClick={() => {
                  setFormSubmitError(null);
                  setFormSuccessMessage(null);
                }}
                className="form-submit-button"
              >
                Поръчай
              </button>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}

function getLabelFromGiftContentType(giftContentType) {
  switch (giftContentType) {
    case "picture":
      return "снимка";
    case "video":
      return "видео";
    case "audio":
      return "звукозапис";
    case "voucher":
      return "ваучер";
    default:
      return "";
  }
}

function getIconFromGiftContentType(giftContentType) {
  switch (giftContentType) {
    case "picture":
      return <Image size={16} weight="bold" className="neutral-color" />;
    case "video":
      return <VideoCamera size={16} weight="bold" className="neutral-color" />;
    case "audio":
      return <Microphone size={16} weight="bold" className="neutral-color" />;
    case "voucher":
      return <FilePdf size={16} weight="bold" className="neutral-color" />;
    default:
      return "";
  }
}

function getAcceptTypeFromGiftContentType(giftContentType) {
  switch (giftContentType) {
    case "picture":
      return "image/*";
    case "video":
      return "video/*";
    case "audio":
      return "audio/*";
    case "voucher":
      return "application/pdf";
    default:
      return "";
  }
}

function getDeliveryAddressLabel(deliveryMethod) {
  if (deliveryMethod === "client_address") {
    return "Адрес за доставка";
  } else if (deliveryMethod === "econt") {
    return "Офис на Еконт";
  } else if (deliveryMethod === "speedy") {
    return "Офис на Спийди";
  }
}

function getDeliveryPrice(deliveryMethod) {
  if (deliveryMethod === "client_address") {
    return 9;
  } else if (deliveryMethod === "econt") {
    return 7;
  } else if (deliveryMethod === "speedy") {
    return 7;
  }
}

export default OrderForm;
