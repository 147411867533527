import { calculateDiscount } from "./utils";

function getFinalPrices(nonDiscountablePrice, price, promoCode) {
  nonDiscountablePrice = nonDiscountablePrice ? nonDiscountablePrice : 0;
  const discountablePrice = price ? price : 0;

  if (!promoCode) {
    return [
      (nonDiscountablePrice + discountablePrice).toFixed(2),
      (nonDiscountablePrice + discountablePrice).toFixed(2),
      false,
    ];
  }

  if ("fixed_price" in promoCode) {
    return [
      (nonDiscountablePrice + promoCode["fixed_price"]).toFixed(2),
      (nonDiscountablePrice + discountablePrice).toFixed(2),
      true,
    ];
  }

  const discount = promoCode ? promoCode["discount_percentage"] : 0;

  const finalPriceWithoutDiscount = (
    discountablePrice + nonDiscountablePrice
  ).toFixed(2);
  const finalPriceWithDiscount = (
    calculateDiscount(discountablePrice, discount) + nonDiscountablePrice
  ).toFixed(2);

  const isPriceReduced = discount > 0;

  return [finalPriceWithDiscount, finalPriceWithoutDiscount, isPriceReduced];
}

function Price(props) {
  const [finalPriceWithDiscount, finalPriceWithoutDiscount, isPriceReduced] =
    getFinalPrices(props.nonDiscountablePrice, props.price, props.promoCode);

  if (!isPriceReduced) {
    return (
      <span style={{ fontFamily: "none" }}>{finalPriceWithDiscount} лв</span>
    );
  }

  return (
    <>
      <span style={{ fontFamily: "none", textDecoration: "line-through" }}>
        {finalPriceWithoutDiscount} лв
      </span>

      <span style={{ fontFamily: "none", color: "#fdc43f" }}>
        {finalPriceWithDiscount} лв
      </span>
    </>
  );
}

export default Price;
